type AmazonPayButtonPlacement =
  | "Home"
  | "Product"
  | "Cart"
  | "Checkout"
  | "Other";

type amazon = {
  Pay: {
    renderButton(
      containerSelector: string,
      options: {
        merchantId: string;
        publicKeyId: string;
        ledgerCurrency: "EUR" | "GBP";
        checkoutLanguage: "en_GB" | "de_DE" | "fr_FR" | "it_IT" | "es_ES";
        productType: "PayAndShip" | "PayOnly" | "SignIn";
        placement: AmazonPayButtonPlacement;
        buttonColor?: "Gold" | "LightGray" | "DarkGray";
      },
    ): {
      // updateButtonInfo: (options) => void;
      onClick(handler: () => void): void;
      initCheckout(options: {
        estimatedOrderAmount?: {
          amount: string;
          currencyCode: string;
        };
        createCheckoutSessionConfig: {
          payloadJSON: string;
          signature: string;
          algorithm: "AMZN-PAY-RSASSA-PSS" | "AMZN-PAY-RSASSA-PSS-V2";
          publicKeyId?: string;
        };
      }): void;
    };
    changeShippingAddress(options: { amazonCheckoutSessionId: string }): void;
    changePaymentMethod(options: { amazonCheckoutSessionId: string }): void;
  };
};

const AMAZON_PAY_PAYMENT_HANDLER = "amazon_payment_handler";

export function useAmazonPay() {
  const {
    public: { amazonPay: config },
  } = useRuntimeConfig();
  const { locale } = useI18n();
  const checkoutLanguage = computed(() => {
    const options = ["en_GB", "de_DE", "fr_FR", "it_IT", "es_ES"] as const;
    const value = options.find((l) => l == locale.value.replace("-", "_"));
    return value || "en_GB";
  });

  async function loadAmazonPayNamespace() {
    await import(/* @vite-ignore */ `${"/js/amazon-pay.js"}`);
    // @ts-expect-error there is no type information tha specifies that window.amazon exists
    return window.amazon as amazon;
  }

  async function renderButton(
    selector: string,
    placement: AmazonPayButtonPlacement,
  ) {
    const amazon = await loadAmazonPayNamespace();
    return amazon.Pay.renderButton(selector, {
      merchantId: config.merchantId,
      publicKeyId: config.publicKeyId,
      ledgerCurrency: useShopCurrency(), // TODO might has to be sourced from somewhere else
      checkoutLanguage: checkoutLanguage.value,
      placement,
      productType: "PayAndShip",
    });
  }

  return { loadAmazonPayNamespace, renderButton, AMAZON_PAY_PAYMENT_HANDLER };
}
